import React, { Component, Suspense } from 'react'
import {HashRouter, Navigate, Route, Routes} from 'react-router-dom'
import './scss/style.scss'
import {NavermapsProvider} from "react-naver-maps";

const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
        <NavermapsProvider
            ncpClientId={'9y3qcrnnqm'}>
            <HashRouter>
              <Suspense fallback={loading}>
                <Routes>
                  <Route exact path="/login" name="Login Page"
                         element={
                           !localStorage.getItem('mint-token') ? (
                               <Login/>
                           ) : (
                               <Navigate to="/dashboard"/>
                           )
                         }/>
                  <Route exact path="/404" name="Page 404" element={<Page404 />} />
                  <Route exact path="/500" name="Page 500" element={<Page500 />} />
                  <Route path="*" name="Home"
                         element={
                           localStorage.getItem('mint-token') ? (
                               <DefaultLayout/>
                           ) : (
                               <Navigate to="/login"/>
                           )
                         }/>
                </Routes>
              </Suspense>
            </HashRouter>
        </NavermapsProvider>
    )
  }
}

export default App
